import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import Josh from "../assets/josh.jpg";

library.add(fab);

function Landing() {
  return (
    <div className="hero">
      <div className="section-center hero-center">
        <article className="hero-info">
          <h1>Joshua Gray</h1>
          <div className="underline"></div>
          <h4>Full Stack Web Developer</h4>
          <a href="/about" className="btn hero-btn">
            more about me
          </a>
          <ul className="hero-icon hero-icons">
            <li>
              <a
                href="https://twitter.com/squashgray"
                rel="noopener noreferrer"
                target="_blank"
                className="social-icon"
              >
                <FontAwesomeIcon icon={["fab", "twitter"]} />
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/in/joshuagraydev/"
                rel="noopener noreferrer"
                target="_blank"
                className="social-icon"
              >
                <FontAwesomeIcon icon={["fab", "linkedin"]} />
              </a>
            </li>
            <li>
              <a
                href="https://github.com/squashgray"
                rel="noopener noreferrer"
                target="_blank"
                className="social-icon"
              >
                <FontAwesomeIcon icon={["fab", "github"]} />
              </a>
            </li>
            <li>
              <a href="mailto:jdgray.dev@gmail.com" className="social-icon">
                <FontAwesomeIcon icon={faEnvelope} />
              </a>
            </li>
            <li>
              <a
                href="https://dev.to/squashgray"
                rel="noopener noreferrer"
                target="_blank"
                className="social-icon"
              >
                <FontAwesomeIcon icon={["fab", "dev"]} />
              </a>
            </li>
          </ul>
        </article>
        <article className="hero-img">
          <img
            src={Josh}
            fluid
            className="hero-photo "
            alt="portrait of Josh Gray"
          />
        </article>
      </div>
    </div>
  );
}

export default Landing;
