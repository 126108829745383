import React from "react";
import Project from "./Project";
import { projects } from "../assets/projects";

function Projects() {
  return (
    <div className="section single-page">
      <div className="section-title">
        <h1>Projects</h1>
        <div className="underline"></div>
      </div>
      <div className="d-flex flex-wrap mt-5 justify-content-around">
        {projects.map((project) => {
          return (
            <>
              <Project
                key={project.project_id}
                project={project}
                image={project.image}
              />
            </>
          );
        })}
      </div>
    </div>
  );
}

export default Projects;
