import React from "react";
import { Navbar, Nav } from "react-bootstrap";

function Header() {
  return (
    <>
      <style type="text/css">
        {`
    .bg-primary {
      background-color: #222!important;
      
    }

    .btn-xxl {
      padding: 1rem 1.5rem;
      font-size: 1.5rem;
    }
    `}
      </style>
      <Navbar collapseOnSelect expand="lg" bg="primary" variant="dark">
        <Navbar.Brand href="/">Joshua Gray</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto"></Nav>
          <Nav className="mr-auto social-icon">
            <Nav.Link className="pr-5 pl-5" href="/">
              Home
            </Nav.Link>
            <Nav.Link className="pr-5 pl-5" href="/about">
              About
            </Nav.Link>
            <Nav.Link className="pr-5 pl-5" href="/projects">
              Projects
            </Nav.Link>
            <Nav.Link className="pr-5 pl-5" href="/contact">
              Contact
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
}

export default Header;
