import React from "react";
import Josh2 from "../assets/Josh2.jpg";
function About() {
  return (
    <div>
      <div className="section single-page">
        <div className="section-title">
          <h1>about me</h1>
          <div className="underline"></div>
        </div>
        <div>
          <div className="section-center about-center">
            <article className="about-img">
              <img
                src={Josh2}
                className="hero-photo"
                alt=" Joshua Gray wearing the infinity gauntlet"
              />
            </article>
            <article className="about-info">
              <div className="section-title about-title">
                <h2>Joshua Gray</h2>
                <div className="underline"></div>
              </div>
              <p>
                I've loved computers since I was a kid playing Warcraft 3 on my
                parents old HP, since then I've gotten an associates degree in
                computer networking and worked for a tech refurbishing company
                for 5 years. However, I am the type of person that loves to
                challenge myself and becoming a developer is one the most
                challenging and rewarding endeavors I have embarked on. I look
                forward to bigger and bolder challenges within the software
                development industry!
              </p>
              <h3>Skills</h3>
              <p>
                <span className="font-weight-bold">LANGUAGES:</span> JavaScript,
                Python
              </p>
              <p>
                {" "}
                <span className="font-weight-bold">FRONT END:</span> React,
                Redux, TypeScript, HTML, CSS, SASS, Material UI
              </p>
              <p>
                {" "}
                <span className="font-weight-bold">BACK END:</span> Node.js,
                Knex, Express, Postgres, SQLite3, SQL, Flask
              </p>
              <a href="/projects" className="btn hero-btn">
                see my work
              </a>
              <a
                href="https://resume.creddle.io/resume/1ie6wn12l1k"
                rel="noopener noreferrer"
                target="_blank"
                className="btn hero-btn ml-3"
              >
                Resume
              </a>
            </article>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
