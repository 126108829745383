import React from "react";
import ContactForm from "./ContactForm";
import { Container, Row, Col } from "react-bootstrap";

export default function Contact() {
  return (
    <div>
      <div class="section single-page">
        <div class="section-title">
          <h1>Contact</h1>
          <div class="underline"></div>
        </div>
        <Container>
          <Row>
            <Col sm={12} md={7}>
              <ContactForm />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}
