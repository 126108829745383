import React from "react";
import { Card, Button, Image, Badge } from "react-bootstrap";

function Project(props) {
  console.log(props);
  return (
    <>
      <Card style={{ width: "25rem" }}>
        <Image className="h-50" fluid variant="top" src={props.image} />
        <Card.Body>
          <Card.Title>{props.project.title}</Card.Title>
          <Card.Text>{props.project.description}</Card.Text>
          {props.project.tech.map((tech) => (
            <>
              <Badge
                key={tech}
                variant="info"
                className="mr-2 mb-1"
                style={{ fontSize: `1rem` }}
              >
                {tech}
              </Badge>
              {` `}
            </>
          ))}
          <div className="d-flex justify-content-start">
            <Button
              size="sm"
              className="button mr-2"
              variant="outline-secondary"
              href={`${props.project.repo}`}
            >
              Github
            </Button>
            <Button
              size="sm"
              className="button mr-2"
              variant="outline-secondary"
              href={`${props.project.url}`}
            >
              Demo
            </Button>
          </div>
        </Card.Body>
      </Card>
    </>
  );
}

export default Project;
