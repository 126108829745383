import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";

library.add(fab);

function Footer() {
  return (
    <div className="main-footer">
      <div className="container">
        <div className="row ">
          <ul className="social-icons">
            <li>
              <a
                href="https://twitter.com/squashgray"
                rel="noopener noreferrer"
                target="_blank"
                className="social-icon"
              >
                <FontAwesomeIcon icon={["fab", "twitter"]} />
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/in/joshuagraydev/"
                rel="noopener noreferrer"
                target="_blank"
                className="social-icon"
              >
                <FontAwesomeIcon icon={["fab", "linkedin"]} />
              </a>
            </li>
            <li>
              <a
                href="https://github.com/squashgray"
                rel="noopener noreferrer"
                target="_blank"
                className="social-icon"
              >
                <FontAwesomeIcon icon={["fab", "github"]} />
              </a>
            </li>
            <li>
              <a
                href="https://dev.to/squashgray"
                rel="noopener noreferrer"
                target="_blank"
                className="social-icon"
              >
                <FontAwesomeIcon icon={["fab", "dev"]} />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        <p className="text-xs-center">
          &copy; {new Date().getFullYear()} Joshua Gray. All Rights Reserved
        </p>
      </div>
    </div>
  );
}

export default Footer;
