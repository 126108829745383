import React from "react";
import { Form, Button } from "react-bootstrap";

export default function ContactForm() {
  return (
    <div>
      <Form action="https://formspree.io/xdowzrgj" method="post">
        <Form.Group controlId="formGroupName">
          <Form.Label>Your Name</Form.Label>
          <Form.Control type="text" name="name" placeholder="Name" />
        </Form.Group>

        <Form.Group controlId="formGroupName">
          <Form.Label>Your Email</Form.Label>
          <Form.Control type="email" name="_replyto" placeholder="Email" />
        </Form.Group>

        <Form.Group controlId="formGroupSubject">
          <Form.Label>Subject</Form.Label>
          <Form.Control type="text" name="subject" placeholder="Subject" />
        </Form.Group>
        <Form.Group controlId="formGroupText">
          <Form.Label>Your Message</Form.Label>
          <Form.Control
            as="textarea"
            name="message"
            rows="4"
            placeholder="Enter your message"
          />
        </Form.Group>
        <Button variant="primary" type="submit" value="Send">
          Submit
        </Button>
        <Button className="ml-3" variant="info" type="reset" value="Reset">
          Reset
        </Button>
      </Form>
    </div>
  );
}
