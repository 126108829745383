export const projects = [
  {
    project_id: 1,
    title: "Story Squad",
    url: "https://master.d37ier1k83jxk6.amplifyapp.com/",
    repo: "https://github.com/Lambda-School-Labs/story-squad-fe/",
    image: require("./StorySquad.PNG"),
    tech: ["JS", "React", "TypeScript", "Material UI"],
    description:
      "A gamified application aims to make reading and writing fun for children",
  },
  {
    project_id: 2,
    title: "Co-Unity",
    url: "https://co-unity.netlify.app/",
    repo: "https://github.com/Team-Button/co-make-fe",
    image: require("./counity3.png"),
    tech: ["JS", "React", "Redux", "React Bootstrap"],
    description:
      "A place for community members to share issues affecting them and others",
  },
  {
    project_id: 3,
    title: "The Tiger King Chronicles",
    url: "https://tigerkingchronicles.herokuapp.com/",
    repo: "https://github.com/Final-Lumb-IV-Legend-of-the-Lost-Lumb/Backend",
    image: require("./tigerking.png"),
    tech: ["Python", "JS", "HTML", "CSS", "Flask"],
    description:
      "A MUD game based off of the Tiger King series(still in development)",
  },
];
