import React from "react";
import { Route, Switch } from "react-router-dom";
import { About, Nav, Contact, Projects, Landing, Footer } from "./components";

import "./App.css";

function App() {
  return (
    <div className="page-container">
      <div className="content-wrap">
        <Nav />

        <Switch>
          <Route exact path="/" component={Landing} />
          <Route path="/about" component={About} />
          <Route path="/projects" component={Projects} />
          <Route path="/contact" component={Contact} />
        </Switch>
      </div>
      <Footer />
    </div>
  );
}

export default App;
